var render = function () {
  var _vm$accountDetails, _vm$accountDetails$ba, _vm$accountDetails2, _vm$accountDetails3, _vm$accountDetails$br, _vm$accountDetails4, _vm$accountDetails5, _vm$accountDetails6, _vm$accountDetails7, _vm$accountDetails8, _vm$accountDetails8$a, _vm$accountDetails8$a2, _vm$accountDetails$ac, _vm$accountDetails9, _vm$accountDetails9$a, _vm$accountDetails9$a2, _vm$accountDetails10, _vm$accountDetails$cr, _vm$accountDetails11, _vm$accountDetails12, _vm$accountDetails$na, _vm$accountDetails13, _vm$accountDetails14, _vm$accountDetails$ac2, _vm$accountDetails15, _vm$accountDetails16, _vm$accountDetails$em, _vm$accountDetails17, _vm$accountDetails18, _vm$accountDetails18$, _vm$accountDetails18$2, _vm$accountDetails19, _vm$accountDetails20, _vm$accountDetails21, _vm$accountDetails$ba2, _vm$accountDetails22, _vm$accountDetails23, _vm$accountDetails$ro, _vm$accountDetails24, _vm$accountDetails25, _vm$accountDetails$ib, _vm$accountDetails26, _vm$accountDetails27, _vm$accountDetails$sw, _vm$accountDetails28, _vm$accountDetails29, _vm$accountDetails$de, _vm$accountDetails30;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "x": "12",
      "md": "4",
      "lg": "4"
    }
  }, [_c('b-card', [_c('b-card-text', [_c('b-list-group', {
    attrs: {
      "flush": ""
    }
  }, [_c('b-list-group-item', [_c('h6', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Bank Name: "), (_vm$accountDetails = _vm.accountDetails) !== null && _vm$accountDetails !== void 0 && _vm$accountDetails.bank_name ? _c('b', [_vm._v(" " + _vm._s((_vm$accountDetails$ba = (_vm$accountDetails2 = _vm.accountDetails) === null || _vm$accountDetails2 === void 0 ? void 0 : _vm$accountDetails2.bank_name) !== null && _vm$accountDetails$ba !== void 0 ? _vm$accountDetails$ba : "N/A") + " ")]) : _vm._e()])]), _c('b-list-group-item', [_c('h6', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Branch Name: "), (_vm$accountDetails3 = _vm.accountDetails) !== null && _vm$accountDetails3 !== void 0 && _vm$accountDetails3.branch_name ? _c('b', [_vm._v(" " + _vm._s((_vm$accountDetails$br = (_vm$accountDetails4 = _vm.accountDetails) === null || _vm$accountDetails4 === void 0 ? void 0 : _vm$accountDetails4.branch_name) !== null && _vm$accountDetails$br !== void 0 ? _vm$accountDetails$br : "N/A") + " ")]) : _c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "light-danger"
    }
  }, [_vm._v(" N/A")])], 1)]), _c('b-list-group-item', [_c('h6', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Status: "), typeof ((_vm$accountDetails5 = _vm.accountDetails) === null || _vm$accountDetails5 === void 0 ? void 0 : _vm$accountDetails5.status) !== 'undefined' ? _c('span', [_c('b-badge', {
    attrs: {
      "pill": "",
      "variant": _vm.statusVariant((_vm$accountDetails6 = _vm.accountDetails) === null || _vm$accountDetails6 === void 0 ? void 0 : _vm$accountDetails6.status)
    }
  }, [_vm._v(" " + _vm._s((_vm$accountDetails7 = _vm.accountDetails) !== null && _vm$accountDetails7 !== void 0 && _vm$accountDetails7.status ? "Active" : "Inactive"))])], 1) : _vm._e()])]), _c('b-list-group-item', [_c('h6', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Account Type: "), (_vm$accountDetails8 = _vm.accountDetails) !== null && _vm$accountDetails8 !== void 0 && (_vm$accountDetails8$a = _vm$accountDetails8.accountType) !== null && _vm$accountDetails8$a !== void 0 && (_vm$accountDetails8$a2 = _vm$accountDetails8$a.data) !== null && _vm$accountDetails8$a2 !== void 0 && _vm$accountDetails8$a2.name ? _c('b', [_c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "light-primary"
    }
  }, [_vm._v(_vm._s((_vm$accountDetails$ac = (_vm$accountDetails9 = _vm.accountDetails) === null || _vm$accountDetails9 === void 0 ? void 0 : (_vm$accountDetails9$a = _vm$accountDetails9.accountType) === null || _vm$accountDetails9$a === void 0 ? void 0 : (_vm$accountDetails9$a2 = _vm$accountDetails9$a.data) === null || _vm$accountDetails9$a2 === void 0 ? void 0 : _vm$accountDetails9$a2.name) !== null && _vm$accountDetails$ac !== void 0 ? _vm$accountDetails$ac : "N/A"))])], 1) : _vm._e()])]), _c('b-list-group-item', [_c('h6', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Created ON: "), (_vm$accountDetails10 = _vm.accountDetails) !== null && _vm$accountDetails10 !== void 0 && _vm$accountDetails10.created_at ? _c('b', [_vm._v(" " + _vm._s((_vm$accountDetails$cr = (_vm$accountDetails11 = _vm.accountDetails) === null || _vm$accountDetails11 === void 0 ? void 0 : _vm$accountDetails11.created_at) !== null && _vm$accountDetails$cr !== void 0 ? _vm$accountDetails$cr : "N/A") + " ")]) : _vm._e()])])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "x": "12",
      "md": "4",
      "lg": "4"
    }
  }, [_c('b-card', [_c('b-card-text', [_c('b-list-group', {
    attrs: {
      "flush": ""
    }
  }, [_c('b-list-group-item', [_c('h6', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Account Name: "), (_vm$accountDetails12 = _vm.accountDetails) !== null && _vm$accountDetails12 !== void 0 && _vm$accountDetails12.name ? _c('b', [_vm._v(" " + _vm._s((_vm$accountDetails$na = (_vm$accountDetails13 = _vm.accountDetails) === null || _vm$accountDetails13 === void 0 ? void 0 : _vm$accountDetails13.name) !== null && _vm$accountDetails$na !== void 0 ? _vm$accountDetails$na : "N/A") + " ")]) : _vm._e()])]), _c('b-list-group-item', [_c('h6', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Account Number: "), (_vm$accountDetails14 = _vm.accountDetails) !== null && _vm$accountDetails14 !== void 0 && _vm$accountDetails14.account_number ? _c('b', [_vm._v(" " + _vm._s((_vm$accountDetails$ac2 = (_vm$accountDetails15 = _vm.accountDetails) === null || _vm$accountDetails15 === void 0 ? void 0 : _vm$accountDetails15.account_number) !== null && _vm$accountDetails$ac2 !== void 0 ? _vm$accountDetails$ac2 : "N/A") + " ")]) : _c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "light-danger"
    }
  }, [_vm._v(" N/A")])], 1)]), _c('b-list-group-item', [_c('h6', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Email: "), (_vm$accountDetails16 = _vm.accountDetails) !== null && _vm$accountDetails16 !== void 0 && _vm$accountDetails16.email ? _c('b', [_vm._v(" " + _vm._s((_vm$accountDetails$em = (_vm$accountDetails17 = _vm.accountDetails) === null || _vm$accountDetails17 === void 0 ? void 0 : _vm$accountDetails17.email) !== null && _vm$accountDetails$em !== void 0 ? _vm$accountDetails$em : "N/A") + " ")]) : _vm._e()])]), _c('b-list-group-item', [_c('h6', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Account Balance: "), (_vm$accountDetails18 = _vm.accountDetails) !== null && _vm$accountDetails18 !== void 0 && (_vm$accountDetails18$ = _vm$accountDetails18.accountType) !== null && _vm$accountDetails18$ !== void 0 && (_vm$accountDetails18$2 = _vm$accountDetails18$.data) !== null && _vm$accountDetails18$2 !== void 0 && _vm$accountDetails18$2.name ? _c('b', [(_vm$accountDetails19 = _vm.accountDetails) !== null && _vm$accountDetails19 !== void 0 && _vm$accountDetails19.balance ? [_c('FormatCurrency', {
    attrs: {
      "amount": (_vm$accountDetails20 = _vm.accountDetails) === null || _vm$accountDetails20 === void 0 ? void 0 : _vm$accountDetails20.balance,
      "currency": (_vm$accountDetails21 = _vm.accountDetails) === null || _vm$accountDetails21 === void 0 ? void 0 : _vm$accountDetails21.currency
    }
  })] : [_c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "light-danger"
    }
  }, [_vm._v(" " + _vm._s((_vm$accountDetails$ba2 = (_vm$accountDetails22 = _vm.accountDetails) === null || _vm$accountDetails22 === void 0 ? void 0 : _vm$accountDetails22.balance) !== null && _vm$accountDetails$ba2 !== void 0 ? _vm$accountDetails$ba2 : "N/A"))])]], 2) : _vm._e()])]), _c('b-list-group-item', [_c('h6', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Routing Number: "), (_vm$accountDetails23 = _vm.accountDetails) !== null && _vm$accountDetails23 !== void 0 && _vm$accountDetails23.routing_number ? _c('b', [_vm._v(" " + _vm._s((_vm$accountDetails$ro = (_vm$accountDetails24 = _vm.accountDetails) === null || _vm$accountDetails24 === void 0 ? void 0 : _vm$accountDetails24.routing_number) !== null && _vm$accountDetails$ro !== void 0 ? _vm$accountDetails$ro : "N/A") + " ")]) : _c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "light-danger"
    }
  }, [_vm._v(" N/A")])], 1)])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "x": "12",
      "md": "4",
      "lg": "4"
    }
  }, [_c('b-card', [_c('b-card-text', [_c('b-list-group', {
    attrs: {
      "flush": ""
    }
  }, [_c('b-list-group-item', [_c('h6', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" IBAN Number: "), (_vm$accountDetails25 = _vm.accountDetails) !== null && _vm$accountDetails25 !== void 0 && _vm$accountDetails25.iban_number ? _c('b', [_vm._v(" " + _vm._s((_vm$accountDetails$ib = (_vm$accountDetails26 = _vm.accountDetails) === null || _vm$accountDetails26 === void 0 ? void 0 : _vm$accountDetails26.iban_number) !== null && _vm$accountDetails$ib !== void 0 ? _vm$accountDetails$ib : "N/A") + " ")]) : _c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "light-danger"
    }
  }, [_vm._v(" N/A")])], 1)]), _c('b-list-group-item', [_c('h6', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Swift Code: "), (_vm$accountDetails27 = _vm.accountDetails) !== null && _vm$accountDetails27 !== void 0 && _vm$accountDetails27.swift_code ? _c('b', [_vm._v(" " + _vm._s((_vm$accountDetails$sw = (_vm$accountDetails28 = _vm.accountDetails) === null || _vm$accountDetails28 === void 0 ? void 0 : _vm$accountDetails28.swift_code) !== null && _vm$accountDetails$sw !== void 0 ? _vm$accountDetails$sw : "N/A") + " ")]) : _c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "light-danger"
    }
  }, [_vm._v(" N/A")])], 1)]), _c('b-list-group-item', [_c('h6', {
    staticClass: "text-base font-weight-semibold"
  }, [_vm._v(" Details: "), (_vm$accountDetails29 = _vm.accountDetails) !== null && _vm$accountDetails29 !== void 0 && _vm$accountDetails29.details ? _c('b', [_vm._v(" " + _vm._s((_vm$accountDetails$de = (_vm$accountDetails30 = _vm.accountDetails) === null || _vm$accountDetails30 === void 0 ? void 0 : _vm$accountDetails30.details) !== null && _vm$accountDetails$de !== void 0 ? _vm$accountDetails$de : "N/A") + " ")]) : _c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "light-danger"
    }
  }, [_vm._v(" N/A")])], 1)])], 1)], 1)], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "id": "remarks-modal",
      "centered": "",
      "title": "Remarks",
      "hide-footer": "",
      "no-close-on-backdrop": ""
    },
    on: {
      "hidden": _vm.hiddenRemarksModal
    }
  }, [_c('b-card-text', [_vm._v(" " + _vm._s(_vm.remarks) + " ")])], 1), [_c('b-card', {
    staticClass: "mt-1"
  }, [_c('div', [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('h3', {
    staticClass: "mb-1"
  }, [_vm._v("Transaction Details")]), _c('vue-excel-xlsx', {
    staticClass: "border-0 bg-transparent",
    attrs: {
      "data": _vm.rows,
      "columns": _vm.exportColumns,
      "file-name": 'Account Details',
      "file-type": 'xlsx',
      "sheet-name": 'Account Details'
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0 mr-1 mb-1",
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" Export ")])], 1)], 1), _c('vue-good-table', {
    attrs: {
      "styleClass": "vgt-table table-custom-style striped",
      "mode": "remote",
      "totalRows": _vm.totalRecords,
      "isLoading": _vm.isLoading,
      "rows": _vm.rows,
      "sort-options": {
        enabled: false,
        multipleColumns: true,
        initialSortBy: [{
          field: 'created_at',
          type: 'asc'
        }]
      },
      "columns": _vm.columns,
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      }
    },
    on: {
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange,
      "update:isLoading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "update:is-loading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        var _props$column, _props$row, _props$row2, _props$row3, _props$row4, _props$row5, _props$row6, _props$row7, _props$row8, _props$column2, _props$row10, _props$row11, _props$column3, _props$row12, _props$row13, _props$column4, _props$row14, _props$row15, _props$row16, _props$column5, _props$row17, _props$row18, _props$column6, _props$row19, _props$row20;
        return [(props === null || props === void 0 ? void 0 : (_props$column = props.column) === null || _props$column === void 0 ? void 0 : _props$column.field) === 'description' ? _c('span', [(_props$row = props.row) !== null && _props$row !== void 0 && _props$row.accountDeposit && (_props$row2 = props.row) !== null && _props$row2 !== void 0 && _props$row2.account_deposit_id ? [_c('span', [_vm._v(" Deposit ")])] : _vm._e(), (_props$row3 = props.row) !== null && _props$row3 !== void 0 && _props$row3.accountTransfer && (_props$row4 = props.row) !== null && _props$row4 !== void 0 && _props$row4.account_transfer_id ? [_c('span', [_vm._v(" Transfer ")])] : _vm._e(), (_props$row5 = props.row) !== null && _props$row5 !== void 0 && _props$row5.milestone && (_props$row6 = props.row) !== null && _props$row6 !== void 0 && _props$row6.milestone_id ? [_c('span', [_vm._v("Milestone")])] : _vm._e(), (_props$row7 = props.row) !== null && _props$row7 !== void 0 && _props$row7.expense && (_props$row8 = props.row) !== null && _props$row8 !== void 0 && _props$row8.expense_id ? [_c('span', [_vm._v("Expense")])] : _vm._e(), _c('div', {
          staticClass: "mt-1"
        }, [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-sm",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              var _props$row9;
              return _vm.showRemarksModal(props === null || props === void 0 ? void 0 : (_props$row9 = props.row) === null || _props$row9 === void 0 ? void 0 : _props$row9.remarks);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "InfoIcon"
          }
        })], 1)], 1)], 2) : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column2 = props.column) === null || _props$column2 === void 0 ? void 0 : _props$column2.field) === 'credit' ? _c('span', [((_props$row10 = props.row) === null || _props$row10 === void 0 ? void 0 : _props$row10.payment_type) == 'credit' ? [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-primary"
          }
        }, [_vm._v(" " + _vm._s((_props$row11 = props.row) === null || _props$row11 === void 0 ? void 0 : _props$row11.amount) + " ")])] : [_vm._v(" - ")]], 2) : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column3 = props.column) === null || _props$column3 === void 0 ? void 0 : _props$column3.field) === 'format_currency' ? [_c('FormatCurrency', {
          attrs: {
            "type": 'currency',
            "amount": (_props$row12 = props.row) === null || _props$row12 === void 0 ? void 0 : _props$row12.balance,
            "currency": props === null || props === void 0 ? void 0 : (_props$row13 = props.row) === null || _props$row13 === void 0 ? void 0 : _props$row13.currency
          }
        })] : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column4 = props.column) === null || _props$column4 === void 0 ? void 0 : _props$column4.field) === 'format_previous_balance' ? _c('span', [((_props$row14 = props.row) === null || _props$row14 === void 0 ? void 0 : _props$row14.previous_balance) > 0 ? [_c('FormatCurrency', {
          attrs: {
            "amount": (_props$row15 = props.row) === null || _props$row15 === void 0 ? void 0 : _props$row15.previous_balance,
            "currency": props === null || props === void 0 ? void 0 : (_props$row16 = props.row) === null || _props$row16 === void 0 ? void 0 : _props$row16.previous_currency
          }
        })] : [_vm._v(" - ")]], 2) : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column5 = props.column) === null || _props$column5 === void 0 ? void 0 : _props$column5.field) === 'format_account_balance' ? _c('span', [_c('FormatCurrency', {
          attrs: {
            "amount": (_props$row17 = props.row) === null || _props$row17 === void 0 ? void 0 : _props$row17.account_balance,
            "currency": props === null || props === void 0 ? void 0 : (_props$row18 = props.row) === null || _props$row18 === void 0 ? void 0 : _props$row18.account_currency
          }
        })], 1) : _vm._e(), (props === null || props === void 0 ? void 0 : (_props$column6 = props.column) === null || _props$column6 === void 0 ? void 0 : _props$column6.field) === 'debit' ? _c('span', [((_props$row19 = props.row) === null || _props$row19 === void 0 ? void 0 : _props$row19.payment_type) == 'debit' ? [_c('b-badge', {
          attrs: {
            "pill": "",
            "variant": "light-danger"
          }
        }, [_vm._v(" " + _vm._s((_props$row20 = props.row) === null || _props$row20 === void 0 ? void 0 : _props$row20.amount) + " ")])] : [_vm._v(" - ")]], 2) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '25', '50', '100', '500']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 1)])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }