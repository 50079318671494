<template>
  <div>
    <b-row>
      <b-col x="12" md="4" lg="4">
        <b-card>
          <b-card-text>
            <b-list-group flush>
              <b-list-group-item
              ><h6 class="text-base font-weight-semibold">
                Bank Name: <b v-if="accountDetails?.bank_name">
                {{ accountDetails?.bank_name ?? "N/A" }}
              </b>
              </h6></b-list-group-item
              >

              <b-list-group-item
              ><h6 class="text-base font-weight-semibold">
                Branch Name: <b v-if="accountDetails?.branch_name">
                {{ accountDetails?.branch_name ?? "N/A" }}
              </b>
                <b-badge v-else pill variant="light-danger">
                  N/A</b-badge
                >
              </h6></b-list-group-item
              >

              <b-list-group-item
              ><h6 class="text-base font-weight-semibold">
                Status: <span v-if="typeof accountDetails?.status !== 'undefined'">
                <b-badge pill :variant="statusVariant(accountDetails?.status)">
                  {{ accountDetails?.status ? "Active" : "Inactive" }}</b-badge
                >
              </span>
              </h6></b-list-group-item
              >

              <b-list-group-item
              ><h6 class="text-base font-weight-semibold">
                Account Type: <b v-if="accountDetails?.accountType?.data?.name">
                <b-badge pill variant="light-primary">{{
                    accountDetails?.accountType?.data?.name ?? "N/A"
                  }}</b-badge>
              </b>
              </h6></b-list-group-item
              >

              <b-list-group-item
              ><h6 class="text-base font-weight-semibold">
                Created ON: <b v-if="accountDetails?.created_at">
                {{ accountDetails?.created_at ?? "N/A" }}
              </b>
              </h6></b-list-group-item
              >

            </b-list-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col x="12" md="4" lg="4">

        <b-card>
          <b-card-text>
            <b-list-group flush>
              <b-list-group-item
              ><h6 class="text-base font-weight-semibold">
                Account Name: <b v-if="accountDetails?.name">
                {{ accountDetails?.name ?? "N/A" }}
              </b>
              </h6></b-list-group-item
              >

              <b-list-group-item
              ><h6 class="text-base font-weight-semibold">
                Account Number: <b v-if="accountDetails?.account_number">
                {{ accountDetails?.account_number ?? "N/A" }}
              </b>
                <b-badge v-else pill variant="light-danger">
                  N/A</b-badge
                >
              </h6></b-list-group-item
              >

              <b-list-group-item
              ><h6 class="text-base font-weight-semibold">
                Email: <b v-if="accountDetails?.email">
                {{ accountDetails?.email ?? "N/A" }}
              </b>
              </h6></b-list-group-item
              >

              <b-list-group-item
              ><h6 class="text-base font-weight-semibold">
                Account Balance: <b v-if="accountDetails?.accountType?.data?.name">

                <template v-if="accountDetails?.balance">
                  <FormatCurrency :amount="accountDetails?.balance" :currency="accountDetails?.currency" />
                </template>
                <template v-else>
                  <b-badge pill variant="light-danger">
                    {{ accountDetails?.balance ?? "N/A" }}</b-badge>
                </template>

              </b>
              </h6></b-list-group-item
              >
              <b-list-group-item
              ><h6 class="text-base font-weight-semibold">
                Routing Number: <b v-if="accountDetails?.routing_number">
                {{ accountDetails?.routing_number ?? "N/A" }}
              </b>
                <b-badge v-else pill variant="light-danger">
                  N/A</b-badge
                >
              </h6></b-list-group-item
              >

            </b-list-group>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col x="12" md="4" lg="4">
        <b-card>
          <b-card-text>
            <b-list-group flush>
              <b-list-group-item
              ><h6 class="text-base font-weight-semibold">
                IBAN Number: <b v-if="accountDetails?.iban_number">
                {{ accountDetails?.iban_number ?? "N/A" }}
              </b>
                <b-badge v-else pill variant="light-danger">
                  N/A</b-badge
                >
              </h6></b-list-group-item
              >

              <b-list-group-item
              ><h6 class="text-base font-weight-semibold">
                Swift Code: <b v-if="accountDetails?.swift_code">
                {{ accountDetails?.swift_code ?? "N/A" }}
              </b>
                <b-badge v-else pill variant="light-danger">
                  N/A</b-badge
                >
              </h6></b-list-group-item
              >

              <b-list-group-item
              ><h6 class="text-base font-weight-semibold">
                Details: <b v-if="accountDetails?.details">
                {{ accountDetails?.details ?? "N/A" }}
              </b>
                <b-badge v-else pill variant="light-danger">
                  N/A</b-badge
                >
              </h6></b-list-group-item
              >

            </b-list-group>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
        id="remarks-modal"
        centered
        title="Remarks"
        hide-footer
        @hidden="hiddenRemarksModal"
        no-close-on-backdrop
    >
      <b-card-text>
        {{ remarks }}
      </b-card-text>
    </b-modal>

    <template>
      <b-card class="mt-1">
        <div>
          <div class="d-flex justify-content-between">
            <h3 class="mb-1">Transaction Details</h3>
            <vue-excel-xlsx
                class="border-0 bg-transparent"
                :data="rows"
                :columns="exportColumns"
                :file-name="'Account Details'"
                :file-type="'xlsx'"
                :sheet-name="'Account Details'"
            >
            <b-button
                class="flex-shrink-0 mr-1 mb-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
            >
              Export
            </b-button>
            </vue-excel-xlsx>
            </div>

          <vue-good-table
              styleClass="vgt-table table-custom-style striped"
              mode="remote"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :totalRows="totalRecords"
              :isLoading.sync="isLoading"
              :rows="rows"
              :sort-options="{
              enabled: false,
              multipleColumns: true,
              initialSortBy: [{ field: 'created_at', type: 'asc' }],
            }"
              :columns="columns"
              :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
          >
            <template slot="table-row" slot-scope="props">
              <!-- Column: description -->
              <span v-if="props?.column?.field === 'description'">
                <!-- accountDeposit -->
                <template
                    v-if="
                    props.row?.accountDeposit && props.row?.account_deposit_id
                  "
                >
                  <span> Deposit </span>
                </template>

                <!-- accountTransfer -->
                <template
                    v-if="
                    props.row?.accountTransfer && props.row?.account_transfer_id
                  "
                >
                  <span> Transfer </span>
                </template>

                <!-- milestone -->
                <template
                    v-if="props.row?.milestone && props.row?.milestone_id"
                >
                  <span>Milestone</span>
                </template>

                <!-- expense -->
                <template v-if="props.row?.expense && props.row?.expense_id">
                  <span>Expense</span>
                </template>

                <div class="mt-1">
                  <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      v-on:click="showRemarksModal(props?.row?.remarks)"
                      class="btn-sm"
                  >
                    <feather-icon icon="InfoIcon" />
                  </b-button>
                </div>
              </span>

              <!-- Column: credit -->
              <span v-if="props?.column?.field === 'credit'">
                <template v-if="props.row?.payment_type == 'credit'">
                  <b-badge pill variant="light-primary">
                    {{ props.row?.amount }}
                  </b-badge>
                </template>
                <template v-else>
                  -
                </template>
              </span>

              <template v-if="props?.column?.field === 'format_currency'">
                <FormatCurrency :type="'currency'" :amount="props.row?.balance" :currency="props?.row?.currency" />
              </template>

              <span v-if="props?.column?.field === 'format_previous_balance'">
                <template v-if="props.row?.previous_balance > 0">
                  <FormatCurrency :amount="props.row?.previous_balance" :currency="props?.row?.previous_currency" />
                </template>
                <template v-else>
                  -
                </template>
              </span>

              <span v-if="props?.column?.field === 'format_account_balance'">
                <FormatCurrency :amount="props.row?.account_balance" :currency="props?.row?.account_currency" />
              </span>

              <!-- Column: debit -->
              <span v-if="props?.column?.field === 'debit'">
                <template v-if="props.row?.payment_type == 'debit'">
                  <b-badge pill variant="light-danger">
                    {{ props.row?.amount }}
                  </b-badge>
                </template>
                <template v-else>
                  -
                </template>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Showing 1 to </span>
                  <b-form-select
                      v-model="pageLength"
                      :options="['10', '25', '50', '100', '500']"
                      class="mx-1"
                      @input="
                      (value) => props.perPageChanged({ currentPerPage: value })
                    "
                  />
                  <span class="text-nowrap">
                    of {{ props.total }} entries
                  </span>
                </div>
                <div>
                  <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                      (value) => props.pageChanged({ currentPage: value })
                    "
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </div>
      </b-card>
    </template>
  </div>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BFormTextarea,
  BSpinner,
  VBTooltip,
  BLink,
  BRow,
  BCol,
  BCardText, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import {
  DIVISION_CREATE,
  DIVISION_EDIT,
  DIVISION_DELETE,
} from "@/helpers/permissionsConstant";
import FormatCurrency from '@/layouts/components/FormatCurrency.vue'

export default {
  name: "AccountDetailsView",
  components: {
    FormatCurrency,
    BListGroupItem,
    BListGroup,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BFormTextarea,
    BLink,
    BRow,
    BCol,
    BCardText,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      DIVISION_CREATE,
      DIVISION_EDIT,
      DIVISION_DELETE,

      accountDetails: "",
      isShowTransactionDetails: false,
      remarks: "",

      pageLength: 10,
      columns: [
        {
          label: "Date",
          field: "created_at",
        },
        {
          label: "Description",
          field: "description",
          sortable: false,
        },
        {
          label: "Currency",
          field: "format_currency",
          sortable: false,
        },
        {
          label: "Previous Balance",
          field: "format_previous_balance",
          sortable: false,
        },
        {
          label: "Credit",
          field: "credit",
          sortable: false,
        },
        {
          label: "Debit",
          field: "debit",
          sortable: false,
        },
        {
          label: "Balance",
          field: "format_account_balance",
          sortable: false,
        },
      ],

      exportColumns: [
        {
          label: "Date",
          field: "created_at",
        },
        {
          label: "Remarks",
          field: "remarks"
        },
        {
          label: "Previous Balance",
          field: "previous_balance",
          sortable: false,
        },
        {
          label: "Payment Type",
          field: "payment_type",
        },
        {
          label: "Amount",
          field: "amount",
        },
        {
          label: "Balance",
          field: "account_balance"
        },
      ],
      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      loading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [{ field: "created_at", type: "desc" }],
        page: 1,
        perPage: 10,
      },
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
    statusVariant() {
      const statusColor = {
        true: "light-success",
        false: "light-danger",
      };

      return (status) => statusColor[status];
    },
  },

  async created() {
    try {
      const accountId = this.$route.params.id;
      const getAccountDetails = await this.getAccountDetails(accountId);

      this.accountDetails = getAccountDetails?.data?.data;
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    showRemarksModal(remarks) {
      if (remarks) {
        this.remarks = remarks;
      } else {
        this.remarks = "N/A";
      }

      this.$bvModal.show("remarks-modal");
    },

    hiddenRemarksModal() {
      this.$bvModal.hide("remarks-modal");
    },

    async showTransactionDetails() {
      // shoe table
      this.isShowTransactionDetails = true;
    },
    async getAccountTransaction(params) {
      return await this.$api.get(`api/accounts/${params.id}/transaction`, {
        params: {
          show: params.show,
          page: params.page,
          sort: params.sort,
        },
      });
    },
    async getAccountDetails(id) {
      return await this.$api.get(`api/accounts/${id}?include=accountType`);
    },

    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    async loadItems() {
      try {
        const [accountInfo] = await Promise.all([
          this.getAccountTransaction({
            id: this.$route.params.id,
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
          }),
        ]);

        const data = accountInfo?.data?.data;
        const meta = accountInfo?.data?.meta;

        this.totalRecords = meta?.pagination?.total;
        this.rows = data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.table-custom-style {
  font-size: 14px !important;
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: left !important;
    text-align: left !important;
  }
}
</style>
